import cx from "classnames"
import PropTypes from "prop-types"
import { ReactComponent as LoaderSvg } from "./Loader.svg"

export function Loader(props) {
  const { className, size, ...rest } = props

  const classNames = cx(
    {
      "w-6 h-6": size == "small",
      "w-10 h-10": size == "medium",
      "w-18 h-18": size == "large",
    },
    className,
    "animate-shrink-pulse"
  )

  return <LoaderSvg className={classNames} {...rest} />
}

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
}

Loader.defaultProps = {
  size: "medium",
}
